





























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import type { InspirationalTile } from '~/components/cms-components/inspirational-tiles/InspirationalTile.vue';
import useWindowResize from '~/hooks/useResizeWindow';

type InspirationalCarousel = {
  id: number;
  code: 'inspirationalcarousel_12';
  elements: InspirationalTile[];
};

export default defineComponent({
  name: 'Gallery',
  props: {
    data: {
      type: Object as PropType<InspirationalCarousel>,
      default: []
    }
  },
  setup() {
    const { isDesktop } = useWindowResize();

    const itemsPerView = ref(3);

    return { isDesktop, itemsPerView };
  }
});
