import { render, staticRenderFns } from "./Gallery.vue?vue&type=template&id=5bd96245&scoped=true&"
import script from "./Gallery.vue?vue&type=script&lang=ts&"
export * from "./Gallery.vue?vue&type=script&lang=ts&"
import style0 from "./Gallery.vue?vue&type=style&index=0&id=5bd96245&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd96245",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsComponentsInspirationalTile: require('/var/www/packages/theme/components/cms-components/inspirational-tiles/InspirationalTile.vue').default,AtomsKECarousel: require('/var/www/packages/theme/components/atoms/KECarousel.vue').default})
